<template>
  <section id="dashboard-compositor">
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
      >
        <comprador-boas-vindas />
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
        <comprador-totalizador />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- Browser States Card -->
      <b-col
        lg="6"
        md="6"
      >
        <comprador-hits-favoritos />
      </b-col>
      <!--/ Browser States Card -->

      <!-- Transaction Card -->
      <b-col
        lg="6"
        md="6"
      >
        <comprador-negociacoes-recentes />
      </b-col>
      <!--/ Transaction Card -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import CompradorBoasVindas from './CompradorBoasVindas.vue'
import CompradorTotalizador from './CompradorTotalizador.vue'
import CompradorHitsFavoritos from './CompradorHitsFavoritos.vue'
import CompradorNegociacoesRecentes from './CompradorNegociacoesRecentes.vue'

export default {
  components: {
    BRow,
    BCol,

    CompradorBoasVindas,
    CompradorTotalizador,
    CompradorHitsFavoritos,
    CompradorNegociacoesRecentes,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
