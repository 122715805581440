<template>
  <b-card
    text-variant="center"
    class="card card-congratulations"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->

    <b-avatar
      variant="primary"
      size="70"
      class="shadow mb-2"
    >
      <feather-icon
        size="28"
        icon="MusicIcon"
      />
    </b-avatar>
    <h3 class="mt-50 text-white">
      Olá, {{ nome }}
    </h3>
    <b-card-text class="m-auto w-75">
      Conecte-se com o seu próximo hit!
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
  data() {
    return {
      nome: null,
    }
  },
  created() {
    this.nomeComprador();
  },
  methods: {
    
    nomeComprador() {

      const userData = getUserData()
      this.nome = userData.name.split(' ')[0] || userData.email

    },
    
  },
}
</script>
