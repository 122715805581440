<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      
      <b-card-title>Hits Favoritados</b-card-title>
        
      <b-card-text class="font-small-2">
          <b-card-text class="font-small-2">
            <b-button
              class="font-small-2" 
              variant="outline-primary" 
              size="sm"
              :to="{ name: 'hits-favoritos'}"
            >
              Ver mais
            </b-button>
          </b-card-text>
        </b-card-text>

    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div 
        v-if="!hitsFavoritos.length"
        class="d-flex justify-content-center align-items-center text-center pt-2 pb-2" 
        style="height: 100%;"
      >
        <div>
          <feather-icon
            size="30"
            icon="StarIcon"
          />
          <h5 class="mt-1">Você ainda não favoritou nenhum hit</h5>
          <small>Os hits favoritados mais recentes serão exibidos aqui</small>
        </div>
      </div>

      <div
        v-for="hit in hitsFavoritos"
        :key="hit.id"
        class="browser-states"
      >
      
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              size="42"
            >
              <feather-icon
                size="18"
                icon="MusicIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ hit.nomeComposicao }} 
            </h6>
            <b-badge
              pill
              variant="light-primary"
              class="text-capitalize"
              v-for="tema in hit.listaTemas"
            >
             {{ tema.nome }}
            </b-badge>  
          </b-media-body>
        </b-media>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BBadge, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BButton,
    BAvatar,
    BBadge,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  data() {
    return {
      hitsFavoritos: [],

      page: 0,
      size: 5,
      sort: 'DESC',
    }
  },
  created() {
    this.hitCompradorFavorito();
  },
  methods: {

    hitCompradorFavorito() {

      useJwt.hitCompradorFavorito({
        page: this.page,
        size: this.size,
        sort: this.sort,
      })
        .then(response => {

          this.hitsFavoritos = response.data.content
          //console.log(response.data.content)

        })
        .catch(error => {
        // this.hits = '';
          console.log(error)
        })
        .finally(() => {
          //this.carregamentoApi = false
        })
    },

  }
}
</script>
